<template>
  <section class="m-setting-password m-scroll">

    <!-- 头部导航 -->
    <m-header title="修改密码"></m-header>

    
    <p class="title">设置新的登录密码</p>

    <div class="input-cell">
      <input v-model="password" type="text" placeholder="6-20位数字/字母/字符组合">
    </div>
  
    <div class="footer">
      <van-button @click="onSubmit" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">确定</van-button>
    </div>


  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { repass } from '@/api/zh/mine.js'
export default {
  name:'PasswordReset',
  components:{ MHeader },
  data(){
    return {
      password:'',
    }
  },

  

  methods:{
   
    
    // 确定
    onSubmit(){
      if(this.password == '') {
        this.$notify({ type: 'warning', message: '请先输入新的密码' })
        return false
      }
      let params = {
        password: this.password
      }
      repass(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg })
          this.$router.go(-2)
        }
      })
      
    }

   
  },
}
</script>

<style lang="less" scoped>
.m-setting-password {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .title {
    margin: 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-left: 20px;
  }
  .input-cell {
    background: #fff;
    padding:0 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 52px;
    >input {
      outline: none;
      border: none;
      font-size: 14px;
    }
    >input::placeholder {
      font-size:14px;
      
      font-weight:400;
      color:rgba(153,153,153,1);
      line-height:20px;
    }
  }
  .footer {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
  }
}
</style>